.boxes {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  height: 40vh;
}
.input-box {
  -ms-flex-item-align: center;
      align-self: center;
  width: 100%;
  padding: 4.5em 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-pack: center;
      justify-content: center;
}

.button-box {
  padding: 0 15px;
  background: #ECEFF1;
  color: #37474f;
  border: 1px solid #ccc;
  border-radius: 3px/6px;
  -webkit-transition:all .3s ease-out;
  -o-transition:all .3s ease-out;
  transition:all .3s ease-out;
}

.button-box:hover {
  -webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
          box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  -webkit-transform:translate(0px,-10px);
  -ms-transform:translate(0px,-10px);
  transform:translate(0px,-10px);
}

/* Box colors */
.bg-1 {
  background: #ECEFF1;
  color: #37474f;
}
.bg-2 {
  background: #7986cb;
  color: #ECEFF1;
}
.bg-3 {
  background: #37474f;
  color: #fff;
}

/* Common button styles */
.button {
  float: left;
  min-width: 150px;
  max-width: 250px;
  /* width: 100%; */
  border: 1px solid rgba(16, 122, 159);
  font-weight: bold;
  display: block;
  margin: 1em;
  padding: 1em 2em;
  border: none;
  background: none;
  color: rgba(16, 122, 159);
  vertical-align: middle;
  position: relative;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
  transition: background 150ms ease-in
}
.button:hover{
  background: rgba(16, 122, 159);
  color: white;
}
.button:focus {
  outline: none;
}
.button > span {
  vertical-align: middle;
}

/* Text color adjustments (we could stick to the "inherit" but that does not work well in Safari) */
.bg-1 .button {
  color: #37474f;
  border-color: #37474f;
}
.bg-2 .button {
  color: #ECEFF1;
  border-color: #ECEFF1;
}
.bg-3 .button {
  color: #fff;
  border-color: #fff;
}

/* Sizes */
.button--size-s {
  font-size: 14px;
}
.button--size-m {
  font-size: 16px;
}
.button--size-l {
  font-size: 18px;
}

/* Typography and Roundedness */
.button--text-upper {
  letter-spacing: 2px;
  text-transform: uppercase;
}
.button--text-thin {
  font-weight: 300;
}
.button--text-medium {
  font-weight: 500;
}
.button--text-thick {
  font-weight: 600;
}
.button--round-s {
  border-radius: 5px;
}
.button--round-m {
  border-radius: 15px;
}
.button--round-l {
  border-radius: 40px;
}

/* Borders */
.button--border-thin {
  border: 1px solid;
}
.button--border-medium {
  border: 2px solid;
}
.button--border-thick {
  border: 3px solid;
}

/* Shikoba */
.button.button--shikoba {
  padding: 1em 30px 1em 50px;
  overflow: hidden;
  background: #fff;
  color: #37474f;
  border-color: #37474f;
  -webkit-transition: background-color 0.3s, border-color 0.3s, color 0.3s;
  -o-transition: background-color 0.3s, border-color 0.3s, color 0.3s;
  transition: background-color 0.3s, border-color 0.3s, color 0.3s;
}
.button--shikoba.button--inverted {
  color: #fff;
  background: #7986cb;
}
.button--shikoba > span {
  display: inline-block;
  -webkit-transform: translate3d(-10px, 0, 0);
  transform: translate3d(-10px, 0, 0);
  -webkit-transition: -webkit-transform 0.3s;
  transition: -webkit-transform 0.3s;
  -o-transition: transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
  -webkit-transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
  -o-transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
     transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
}
.button--shikoba .button__icon {
  position: absolute;
  left: 20px;
  font-size: 18px;
  -webkit-transform: translate3d(-40px, 2.5em, 0);
  transform: translate3d(-40px, 2.5em, 0);
  -webkit-transition: -webkit-transform 0.3s;
  transition: -webkit-transform 0.3s;
  -o-transition: transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
  -webkit-transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
  -o-transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
     transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
}
.button--shikoba:hover .button__icon,
.button--shikoba:hover > span {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.button--shikoba:hover {
  background: RGB(16, 122, 159);
  border-color: RGB(16, 122, 159);
  color: #fff;
}
