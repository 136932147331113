.modal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
}
.modal.opened {
  display: block;
}
.modal .content {
  border-radius: 8px;
  width: 80vw;
  height: 80vh;
  margin: auto;
  margin-top: 10vh;
  background-color: white;
  overflow: hidden;
}

.modal .icon-close {
  position: absolute;
  top: 5vh;
  right: 5vw;
  width: 20px;
  height: 20px;
  background-image: url("../../assets/close.svg");
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
}

.modal .modal-title {
  height: 20vh;
  background-color: rgba(16, 122, 159);
}

.modal .modal-content {
  margin-top: 10vh;
}

.modal .modal-title h1 {
  height: 100%;
  margin: 0;
  padding: 0 10px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  color: white;
  font-size: 1.2em;
  -ms-flex-pack: center;
      justify-content: center;
}
