.form-group {
  margin: 1em 0 1em 0;
}
.hiddenWAI {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}
input {
  background-color: #ffffff;
  background-image: none;
  border: 1px solid #e5e6e7;
  border-radius: 1px;
  height: 44px;
  color: #777777;
  display: block;
  padding: 6px 12px;
  -webkit-transition: border-color 0.15s ease-in-out 0s, -webkit-box-shadow 0.15s ease-in-out 0s;
  transition: border-color 0.15s ease-in-out 0s, -webkit-box-shadow 0.15s ease-in-out 0s;
  -o-transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
  transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
  transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s, -webkit-box-shadow 0.15s ease-in-out 0s;
  width: 100%;
  font-size: 14px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.has-error {
  border-color: red;
}

.form-control:focus {
  border-color: #777;
  outline: 0;
}
*::-webkit-input-placeholder {
  color: #98a1a8;
  font-size: 16px;
}
.button-primary{
  -webkit-appearance: button;
  cursor: pointer;
  background-color: #006089;
  color: #ffffff;
  margin-bottom: 1em;
  width: 100%;
  border-radius: 3px;
  padding: 0.5em 2em;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  border: 1px solid #006089;
}
.button-primary:hover {
  background-color: #1a7bb9;
  border: 1px solid #1a7bb9;
}
