.Layout {
  width: 100vw;
  height: 100vh;
  position: relative;
  overflow-x: hidden;
  background: url('../../assets/bg-amorc.jpg') no-repeat center fixed;
  background-size: cover;
  font-family: "open sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.content.box {
  margin: 0 auto;
  text-align: center;
}

.panel.box {
  min-height: 100vh;
  background-color: #ffffff;
  -webkit-border-image: none;
       -o-border-image: none;
          border-image: none;
  border-style: solid solid none;
  border-width: 3px 0 0;
  color: inherit;
  margin-bottom: 0;
  padding: 1em 1em 1em 1em;
  border-color: #006089;
}

.panel.head-box {
  margin-top: 2em;
  margin-bottom: 1em;
  text-align: center;
}
.panel.head-title {
  margin-top: 1em;
  color: #006089;
}
.panel.head-title strong {
  font-weight: 900;
}
.panel.head-content-divisor {
  width: 80vw;
  border: 0;
  height: 1px;
  background: #333333;
  background-image: -webkit-gradient(linear, left top, right top, from(#fedf9a), color-stop(#FEBD33), to(#fedf9a));
  background-image: -webkit-linear-gradient(left, #fedf9a, #FEBD33, #fedf9a);
  background-image: -o-linear-gradient(left, #fedf9a, #FEBD33, #fedf9a);
  background-image: linear-gradient(to right, #fedf9a, #FEBD33, #fedf9a);
}

.form-group {
  min-width: 80vw;
}

.panel.children {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: column wrap;
      flex-flow: column wrap;
  -ms-flex-align: center;
      align-items: center;
}

.alert {
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 4px;
}

.alert-danger {
  color: #a94442;
  background-color: #f2dede;
  border-color: #ebccd1;
}
