.content-title {
  color: #777;
  font-size: 16px;
  margin-bottom: 2rem;
}

a {
  color: #337ab7;
  text-decoration: none;
  display: block;
  padding-bottom: 5px;
  font-size: 16px;
}
